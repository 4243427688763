import styled from 'styled-components'

import { ProductCarouselBase } from 'components/Carousels/CarouselProductBase'

import { Heading } from '@cellulargoods/core'
import { Sanity } from '@cellulargoods/types'
import { FONT_STYLE_SOFIA_32_500, MEDIA_QUERIES } from '@cellulargoods/styles'

export type RelatedProductProps = Omit<
  Sanity.Keyed<Sanity.RelatedProducts>,
  'products'
> & {
  relatedProducts?: Sanity.ProductCollection
}

export const RelatedProducts = ({
  title,
  relatedProducts,
}: RelatedProductProps) => {
  const { selected_products: selectedProducts } = relatedProducts ?? {}

  return (
    <RelatedProductsContainer>
      {title ? (
        <RelatedProductsTitle tag="h2" fontStyle={FONT_STYLE_SOFIA_32_500}>
          {title}
        </RelatedProductsTitle>
      ) : null}
      <RelatedProductsCarousel>
        {selectedProducts ? (
          <ProductCarouselBase isRelatedProducts products={selectedProducts} />
        ) : null}
      </RelatedProductsCarousel>
    </RelatedProductsContainer>
  )
}

const RelatedProductsContainer = styled.section`
  padding-bottom: 8rem;
`

const RelatedProductsTitle = styled(Heading)`
  margin: 4rem 2rem;

  ${MEDIA_QUERIES.desktopUp} {
    margin: 8rem 4rem;
  }
`

const RelatedProductsCarousel = styled.div`
  ${MEDIA_QUERIES.desktopUp} {
    padding: 0 4rem;
  }
`
