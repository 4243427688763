import styled, { css } from 'styled-components'

import { Sanity } from '@cellulargoods/types'
import { MEDIA_QUERIES } from '@cellulargoods/styles'
import { Carousel, CarouselFlex } from '@cellulargoods/core'

import { ProductTile, ContentWrap } from 'components/Products/ProductTile'

export type ProductCarouselBaseProps = {
  products?: Sanity.Product[]
  className?: string
  showAddToCartOnHover?: boolean
  isRelatedProducts?: boolean
}

export const ProductCarouselBase = ({
  products = [],
  className,
  isRelatedProducts,
}: ProductCarouselBaseProps) => {
  const forceSize = products.length <= 3

  return (
    <CarouselBase
      disableDrag={forceSize}
      hideButtons={forceSize}
      forceSize={forceSize}
      className={className}
    >
      {products.map((product) => (
        <CarouselProductTile
          key={product?._id}
          forceSize={forceSize}
          $isRelatedProducts={isRelatedProducts}
          {...product}
        />
      ))}
    </CarouselBase>
  )
}

const CarouselBase = styled(Carousel)<{ forceSize: boolean }>`
  ${MEDIA_QUERIES.desktopUp} {
    padding-bottom: 8rem;
  }

  ${CarouselFlex} {
    ${(props) =>
      props.forceSize
        ? css`
            flex-direction: column;
            align-items: initial;
          `
        : css`
            gap: 2rem;
            align-items: stretch;
          `}

    ${MEDIA_QUERIES.desktopUp} {
      padding: 0;
      flex-direction: row;
      gap: 2rem;
    }
  }
`

const CarouselProductTile = styled(ProductTile)<{
  forceSize: boolean
  $isRelatedProducts?: boolean
}>`
  ${(props) =>
    props.forceSize
      ? css`
          width: 100%;

          & + & {
            margin-top: 4rem;
          }
        `
      : css`
          flex: 0 0 100%;
        `}

  ${ContentWrap} {
    padding: ${(props) => (props.$isRelatedProducts ? '0 2rem' : '')};
  }

  ${MEDIA_QUERIES.desktopUp} {
    ${(props) =>
      props.forceSize
        ? css`
            max-width: 33%;

            & + & {
              margin-top: 0;
            }

            ${ContentWrap} {
              padding: unset;
            }
          `
        : css`
            flex: 1 0 calc(33% - 1rem);
          `}
  }
`
