import { forwardRef, useState } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { encode } from 'shopify-gid'
import { animated, useSpring } from '@react-spring/web'

import { Maybe, Sanity } from '@cellulargoods/types'
import {
  Media,
  ButtonTypes,
  Button,
  Bg,
  createSlug,
  SlugType,
  Heading,
  IsNewTag,
  SoldOutOrnament,
  OnSaleIndicator,
} from '@cellulargoods/core'
import {
  FONT_STYLE_SOFIA_18_500,
  FONT_STYLE_SOFIA_22_500,
  aspectRatio,
  MEDIA_QUERIES,
  getFontStyles,
  FONT_STYLE_SOFIA_18_400,
  FONT_STYLE_SOFIA_22_400,
} from '@cellulargoods/styles'
import AddToCartSVG from 'assets/addToCart.svg'
import { useCart } from 'hooks/useCart'
import useProductVariant from 'hooks/useProductVariant'
import { getPrimaryCtaCopy } from 'helpers/shopify'
import { useCurrency, useExchangeRate } from '@cellulargoods/hooks'
import { EVERYTHING_IS_SOLD_OUT } from 'references/constants'

export type ProductTileProps = Maybe<Sanity.Product> & {
  className?: string
}

export const ProductTile = forwardRef<HTMLDivElement, ProductTileProps>(
  (props, ref) => {
    const {
      ukOnly,
      product,
      image,
      title,
      className,
      category,
      slug,
      status,
      hidePrice,
      isNew,
      customTag,
      hasComparePrice,
    } = props!
    const [hovered, setHovered] = useState(false)
    const [currency] = useCurrency()
    const exchangeRates = useExchangeRate()
    const { lineItemAdd, lineItemMutatingID } = useCart()

    const { activeVariant, productPrice } = useProductVariant(
      product && product.variants
    )

    const price =
      currency === 'USD'
        ? '$' +
          Math.ceil(
            Number(productPrice.split('£')[1]) * exchangeRates[currency][0]
          ).toFixed(2)
        : currency === 'EUR'
        ? '€' +
          Math.ceil(
            Number(productPrice.split('£')[1]) * exchangeRates[currency][0]
          ).toFixed(2)
        : productPrice

    const compareAtPrice =
      product !== undefined
        ? product.compareAtPrice === 0
          ? undefined
          : currency === 'USD'
          ? '$' +
            Math.ceil(
              product.compareAtPrice! * exchangeRates[currency][0]
            ).toFixed(0)
          : currency === 'EUR'
          ? '€' +
            Math.ceil(
              product.compareAtPrice! * exchangeRates[currency][0]
            ).toFixed(0)
          : '£' + product.compareAtPrice?.toFixed(0)
        : null

    const customTagMessage =
      hasComparePrice?.message ||
      hasComparePrice?.messageUs ||
      hasComparePrice?.messageEu

    const isUk = ukOnly ? ukOnly && currency === 'GBP' : true
    const availableForSale =
      !EVERYTHING_IS_SOLD_OUT && Boolean(activeVariant.inStock) && isUk

    const productVariantId = encode(
      'ProductVariant',
      activeVariant.id
    ) as string

    const styles = useSpring({
      opacity: productVariantId === lineItemMutatingID ? 0.35 : hovered ? 1 : 0,
      scale: hovered ? 1 : 0.8,
      config: {
        mass: 0.4,
        tension: 110,
        friction: 10,
      },
    })

    if (!category || !product || !product.variants) {
      return null
    }

    const handleAddToCart = async () => {
      try {
        if (productVariantId) {
          await lineItemAdd({
            quantity: 1,
            productVariantId,
          })
        }
      } catch (err) {
        console.error(err)
      }
    }

    const handlePointerEnter = () => {
      if (status !== Sanity.ProductStatus.INTEREST && availableForSale) {
        setHovered(true)
      }
    }

    const handlePointerLeave = () => {
      if (status !== Sanity.ProductStatus.INTEREST && availableForSale) {
        setHovered(false)
      }
    }
    return (
      <Wrap
        ref={ref}
        className={className}
        onPointerLeave={handlePointerLeave}
        onPointerEnter={handlePointerEnter}
        data-testid="product-tile"
      >
        {image && (
          <ImageWrap>
            <Link
              href={createSlug(SlugType.FUNCTIONAL_PDP, [
                category.slug?.current ?? '',
                slug?.current
                  ? `${slug?.current}${
                      product.variants.length > 1
                        ? `?variantId=${activeVariant?.id}`
                        : ''
                    }`
                  : '',
              ])}
            >
              <a>
                <LinkWrap>
                  <Media {...image} sizes={['100vw', null, null, '33vw']} />
                </LinkWrap>
              </a>
            </Link>
            <TagsWrap>
              {isNew ? (
                <IsNewTag>{customTag ? customTag : `New`}</IsNewTag>
              ) : null}
              {hasComparePrice?.showComparePrice && customTagMessage ? (
                <OnSaleIndicator
                  text={
                    currency === 'USD'
                      ? hasComparePrice.messageUs
                      : currency === 'EUR'
                      ? hasComparePrice.messageEu
                      : hasComparePrice.message
                  }
                />
              ) : null}
              {!availableForSale &&
                status !== Sanity.ProductStatus.INTEREST && <SoldOut />}
            </TagsWrap>

            {availableForSale && status !== Sanity.ProductStatus.INTEREST ? (
              <AddToCartPositioner>
                <AddToCartButton
                  type="button"
                  aria-label={`Add ${title} to cart`}
                  onClick={handleAddToCart}
                  disabled={productVariantId === lineItemMutatingID}
                  style={styles}
                >
                  <AddToCartSVG size={32} stroke="black" />
                </AddToCartButton>
              </AddToCartPositioner>
            ) : null}
          </ImageWrap>
        )}
        <ContentWrap>
          <ProductTitleWrap>
            <ProductTitle tag="h3">{title}</ProductTitle>
            <PriceContainer>
              <ProductPrice tag="h4">{!hidePrice ? price : ''}</ProductPrice>
              {hasComparePrice?.showComparePrice && compareAtPrice ? (
                <RRP tag="h4">RRP {compareAtPrice}</RRP>
              ) : null}
            </PriceContainer>
          </ProductTitleWrap>
          {(product.variants.length === 1 &&
            activeVariant.title !== 'Default Title') ||
          product.variants.length > 1 ? (
            <ProductInfo tag="h5" fontStyle={FONT_STYLE_SOFIA_18_500}>
              {product.variants.length === 1
                ? activeVariant.title !== 'Default Title'
                  ? activeVariant.title
                  : ''
                : `${product.variants.length} sizes`}
            </ProductInfo>
          ) : null}
          <ProductPrimaryAction
            variant={ButtonTypes.PRIMARY}
            tag="a"
            href={createSlug(SlugType.FUNCTIONAL_PDP, [
              category.slug?.current ?? '',
              slug?.current
                ? `${slug?.current}${
                    product.variants.length > 1
                      ? `?variantId=${activeVariant?.id}`
                      : ''
                  }`
                : '',
            ])}
            disabled={productVariantId === lineItemMutatingID}
            isFullWidth={true}
            bg={Bg.white}
          >
            {getPrimaryCtaCopy(
              status! as Sanity.ProductStatus,
              !availableForSale
            )}
          </ProductPrimaryAction>
        </ContentWrap>
      </Wrap>
    )
  }
)

const AddToCartPositioner = styled.div`
  position: absolute;
  left: unset !important;
  right: 10px !important;
  top: 10px !important;
  transform: none !important;
`

const AddToCartButton = styled(animated.button)<{ disabled: boolean }>`
  width: 5rem;
  height: 5rem;
  border-radius: 50%;
  border: solid 1px var(--black);
  background-color: var(--lightBrown);

  padding: 0;
  margin: 0;

  & > svg {
    position: relative;
    top: 0.2rem;
  }

  cursor: pointer;

  @media (hover: none) {
    transform: scale(1) !important;
    opacity: 1 !important;
  }

  pointer-events: ${(props) => (props.disabled ? 'none' : 'auto')};
`

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`

const ImageWrap = styled.div`
  position: relative;
  background: var(--softGrey);
`
const LinkWrap = styled.div`
  ${aspectRatio(315, 458)}

  ${MEDIA_QUERIES.desktopUp} {
    ${aspectRatio(440, 640)}
  }
`

export const ContentWrap = styled.div`
  margin: 1rem 0;
  position: relative;
  flex: 1;
  display: flex;
  flex-direction: column;
`

const ProductTitleWrap = styled.div`
  display: flex;
  gap: 2rem;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 2rem;
  flex: 1;
`

const ProductTitle = styled(Heading)`
  ${getFontStyles(FONT_STYLE_SOFIA_18_500)}
  flex: 0 0 70%;

  ${MEDIA_QUERIES.largeDesktopUp} {
    ${getFontStyles(FONT_STYLE_SOFIA_22_500)}
  }
`

const PriceContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const ProductPrice = styled(Heading)`
  ${getFontStyles(FONT_STYLE_SOFIA_18_400)}
  text-align: right;
  flex: 1;

  ${MEDIA_QUERIES.largeDesktopUp} {
    ${getFontStyles(FONT_STYLE_SOFIA_22_400)}
  }
`

const RRP = styled(Heading)`
  ${getFontStyles(FONT_STYLE_SOFIA_18_400)}
  color: var(--accessibleGrey);
  text-decoration: line-through;
  ${MEDIA_QUERIES.largeDesktopUp} {
    ${getFontStyles(FONT_STYLE_SOFIA_22_400)}
  }
`

const ProductInfo = styled(Heading)`
  margin-bottom: 2rem;
`

const ProductPrimaryAction = styled(Button)`
  margin-bottom: 2rem;
`

const TagsWrap = styled.div`
  display: flex;
  gap: 5px;
  position: absolute;
  top: 10px;
  left: 10px;
`

const SoldOut = styled(SoldOutOrnament)`
  text-align: center;
`
